<template>
  <div class="card card-custom gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label primary--text font-weight-bold"
          >Senarai Notifikasi</span
        >
      </h3>
    </div>
    <div class="card-body py-0">
      <div class="table-responsive table-hover">
        <table class="table table-head-custom table-vertical-center">
          <thead>
            <tr class="text-center">
              <th class="pl-0">Tarikh</th>
              <th class="pl-0">Mesej</th>
              <th class="pl-0">Tindakan</th>
            </tr>
          </thead>
          <tbody
            id="loader"
            v-if="notifications.data == undefined || notifications.data.length == 0"
          >
            <template>
              <tr>
                <td colspan="3">
                  <span
                    class="text-center text-dark-75 font-weight-bolder d-block font-size-md"
                  >
                    {{ notifications.message }}
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <template>
              <tr
                class="text-center"
                v-for="notification in notifications.data"
                :key="notification.id"
                @click="showDetails(notification)"
              >
                <td class="pl-0" style="vertical-align: top;">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-md"
                    style="white-space: nowrap;"
                  >
                    {{ notification.created_at | formatDateWordy }}<br/>
                    {{ notification.created_at | formatTime }}
                  </span>
                </td>
                <td class="pl-0" style="vertical-align: top;">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-md"
                    style="text-align: justify"
                  >
                    {{ notification.data.title || notification.data.subject }}
                  </span>
                </td>
                <td style="vertical-align: top;">
                  <v-btn
                    icon
                    class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    @click="showDetails(notification)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/General/Visible.svg"
                      ></inline-svg>
                    </span>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <nav>
        <ul class="pagination justify-content-center">
          <div v-for="link in notifications.data.links" :key="link.label">
            <li v-bind:class="`page-item ${link.url == null ? 'disabled' : ''} ${link.active == true ? 'active' : ''
            }`">
              <a class="page-link" @click="paginate(link.url)" :aria-label="link.label">{{
                  decodeHtml(link.label)
              }}</a>
            </li>
          </div>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationList",

  props: {
    notifications: {},
  },

  data() {
    return {
      overlay: true,
    };
  },

  mounted() {
    this.closeOverlay();
    //nfh-console.log("NotificationList notifications:", this.notifications);
  },

  methods: {
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },

    async paginate(url) {
      this.$emit("paginate", url);
    },

    showDetails(notification) {
      this.$emit("showNotificationDetailsDialog", notification);
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    },
  },
};
</script>

<style scoped>
#loader {
  width: 100%;
  height: 100px;
}
</style>
