var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "div",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("NotificationList", {
                        attrs: { notifications: _vm.notifications },
                        on: {
                          paginate: _vm.paginate,
                          showNotificationDetailsDialog:
                            _vm.showNotificationDetailsDialog,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("NotificationDetailDialog", {
                attrs: {
                  notification: _vm.notification,
                  showBackButton: true,
                  backButton: "Tutup",
                },
                model: {
                  value: _vm.showNotificationDetails,
                  callback: function ($$v) {
                    _vm.showNotificationDetails = $$v
                  },
                  expression: "showNotificationDetails",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }