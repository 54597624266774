var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-custom gutter-b" }, [
    _vm._m(0),
    _c("div", { staticClass: "card-body py-0" }, [
      _c("div", { staticClass: "table-responsive table-hover" }, [
        _c(
          "table",
          { staticClass: "table table-head-custom table-vertical-center" },
          [
            _vm._m(1),
            _vm.notifications.data == undefined ||
            _vm.notifications.data.length == 0
              ? _c(
                  "tbody",
                  { attrs: { id: "loader" } },
                  [
                    [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-center text-dark-75 font-weight-bolder d-block font-size-md",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.notifications.message) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                  ],
                  2
                )
              : _c(
                  "tbody",
                  [
                    _vm._l(_vm.notifications.data, function (notification) {
                      return _c(
                        "tr",
                        {
                          key: notification.id,
                          staticClass: "text-center",
                          on: {
                            click: function ($event) {
                              return _vm.showDetails(notification)
                            },
                          },
                        },
                        [
                          _c(
                            "td",
                            {
                              staticClass: "pl-0",
                              staticStyle: { "vertical-align": "top" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-dark-75 font-weight-bolder d-block font-size-md",
                                  staticStyle: { "white-space": "nowrap" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDateWordy")(
                                          notification.created_at
                                        )
                                      )
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatTime")(
                                          notification.created_at
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "pl-0",
                              staticStyle: { "vertical-align": "top" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-dark-75 font-weight-bolder d-block font-size-md",
                                  staticStyle: { "text-align": "justify" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        notification.data.title ||
                                          notification.data.subject
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            { staticStyle: { "vertical-align": "top" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "btn btn-icon btn-light btn-hover-primary btn-sm mx-3",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDetails(notification)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "svg-icon svg-icon-md svg-icon-primary",
                                    },
                                    [
                                      _c("inline-svg", {
                                        attrs: {
                                          src: "media/svg/icons/General/Visible.svg",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
          ]
        ),
      ]),
      _c("nav", [
        _c(
          "ul",
          { staticClass: "pagination justify-content-center" },
          _vm._l(_vm.notifications.data.links, function (link) {
            return _c("div", { key: link.label }, [
              _c(
                "li",
                {
                  class:
                    "page-item " +
                    (link.url == null ? "disabled" : "") +
                    " " +
                    (link.active == true ? "active" : ""),
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      attrs: { "aria-label": link.label },
                      on: {
                        click: function ($event) {
                          return _vm.paginate(link.url)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.decodeHtml(link.label)))]
                  ),
                ]
              ),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header border-0 py-5" }, [
      _c("h3", { staticClass: "card-title align-items-start flex-column" }, [
        _c(
          "span",
          { staticClass: "card-label primary--text font-weight-bold" },
          [_vm._v("Senarai Notifikasi")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "text-center" }, [
        _c("th", { staticClass: "pl-0" }, [_vm._v("Tarikh")]),
        _c("th", { staticClass: "pl-0" }, [_vm._v("Mesej")]),
        _c("th", { staticClass: "pl-0" }, [_vm._v("Tindakan")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }