import { render, staticRenderFns } from "./Notification.vue?vue&type=template&id=7e50bac4&"
import script from "./Notification.vue?vue&type=script&lang=js&"
export * from "./Notification.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VOverlay,VProgressCircular,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/fared/kowamas-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e50bac4')) {
      api.createRecord('7e50bac4', component.options)
    } else {
      api.reload('7e50bac4', component.options)
    }
    module.hot.accept("./Notification.vue?vue&type=template&id=7e50bac4&", function () {
      api.rerender('7e50bac4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/pages/notifications/Notification.vue"
export default component.exports