<template>
  <div>
    <v-row justify="center">
      <v-col cols="12">
        <div>
          <v-col cols="12">
            <NotificationList
              :notifications="notifications"
              @paginate="paginate"
              @showNotificationDetailsDialog="showNotificationDetailsDialog"
            ></NotificationList>
          </v-col>
        </div>
        <NotificationDetailDialog
          v-model="showNotificationDetails"
          :notification="notification"
          :showBackButton="true"
          backButton='Tutup'
        ></NotificationDetailDialog>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import {
  mapGetters,
} from "vuex";

import {
  SET_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module";

import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_BY_PAGE,
  SET_NOTIFICATIONS,
} from "@/core/services/store/notification.module";

import NotificationList from "@/view/pages/widgets/NotificationList.vue";
import NotificationDetailDialog from "@/view/pages/widgets/NotificationDetailDialog.vue";

export default {
  name: "Notification",

  components: {
    NotificationList,
    NotificationDetailDialog,
  },

  data() {
    return {
      showNotificationDetails: false,
      notification: {},
      overlay: true,
    };
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserRoles",
      "notifications",
    ]),
  },

  async mounted() {
    // await this.$store.dispatch(GET_NOTIFICATIONS).then((response) => {
    //   if (response.success == true) {
    //     this.$store.commit(SET_NOTIFICATIONS, response);
    //   }
    // });
    await this.$store.dispatch(GET_NOTIFICATIONS);
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Notifikasi" }]);
    this.closeOverlay();
  },

  methods: {
    async paginate(url) {
      this.openOverlay();

      await this.$store
        .dispatch(GET_NOTIFICATIONS_BY_PAGE, url)
        .then(async (response) => {
          this.$store.commit(SET_NOTIFICATIONS, response.data);
        })
        .finally (() => {
          this.closeOverlay();
        });
    },

    showNotificationDetailsDialog(notification) {
      this.showNotificationDetails = true;
      this.notification = notification;
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 1000);
    },
  },
};
</script>